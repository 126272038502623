import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewWindow } from '@link/gds-share/icons';
import { LinksIcon } from '@link/gds-supplier/assets/icons';
import cls from 'clsx';
import { Dropdown, Link as ExternalLink } from '@linktivity/link-ui';
import styles from './header.module.css';

const externalLinks = [
  {
    href: import.meta.env.VITE_APP_OLD_GDS_HOST,
    label: 'GDSシステム'
  },
  {
    href: import.meta.env.VITE_APP_ETICKTET_HOST,
    label: 'E-ticketシステム'
  }
];

const RelatedLinks = () => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const emitButton = useMemo(
    () => (
      <button
        className={styles.button}
        onClick={() => setOpenMenu(visible => !visible)}
      >
        <LinksIcon className={styles.icon} />
        <span className={styles.text}>{t('layouts.relatedLinks.links')}</span>
      </button>
    ),
    [t]
  );

  return (
    <Dropdown
      target={emitButton}
      sameWidth={false}
      visible={openMenu}
      onClose={() => setOpenMenu(false)}
    >
      <Dropdown.Menu className={styles.link}>
        {externalLinks.map(link => (
          <Dropdown.Item key={link.href}>
            <ExternalLink
              href={link.href}
              className={cls(styles.dropdownButton, styles.linkItem)}
            >
              <NewWindow className={styles.linkIcon} />
              <span className={styles.dropdownText}>{link.label}</span>
            </ExternalLink>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default memo(RelatedLinks);
