import { useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Loader } from '@link/gds-share/components';
import { ROUTE_PATH } from '@link/gds-share/constants/path';
import { useBreadcrumb } from '@link/gds-supplier/hooks';
import { useStore } from '@link/gds-supplier/stores';
import Header from '../Header';
import Sidebar from './Sidebar';
import styles from './backend.module.css';

const BackendLayout = () => {
  const { t } = useTranslation();
  const { inventory, breadcrumb, timeZone } = useStore();
  const { setBreadcrumbRoutes } = useBreadcrumb(inventory);
  const { poolId } = useParams();

  useEffect(() => {
    if (poolId) {
      const routes = [
        {
          to: `/${ROUTE_PATH.backendSettings}`,
          name: t('layouts.sidebar.backendInventory')
        }
      ];
      setBreadcrumbRoutes(routes);
    }

    return () => {
      breadcrumb.clear();
    };
  }, [poolId, breadcrumb, t, setBreadcrumbRoutes]);

  useEffect(() => {
    if (poolId) {
      inventory.getInventory(poolId);
    }
    return () => {
      inventory.clearInventory();
    };
  }, [inventory, poolId]);

  useEffect(() => {
    timeZone.getTimeZones();
  }, [timeZone]);

  return (
    <div className={styles.backend}>
      <Header />
      <div className={styles.container}>
        <Sidebar />
        <main className={styles.main}>
          <div className={styles.inner}>
            {inventory.loaded ? <Outlet /> : <Loader />}
          </div>
        </main>
      </div>
    </div>
  );
};
const ObserverBackendLayout = observer(BackendLayout);
export default ObserverBackendLayout;
