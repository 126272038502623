// Generated i18n file
export default {
  component: {
    form: {
      id: '예약정보ID',
      title: '예약정보 제목',
      info: '예약정보 설명',
      setting: '예약정보 설정 방법',
      deadline: '마감 종류',
      answerType: '응답 형식',
      selectItems: '선택 항목',
      selectPrompt: '선택해주세요'
    },
    rule: {
      type: '예약 타입',
      possibleInterval: '예약 가능 기간',
      interval: '예약 기한',
      daysBefore: '일 전',
      timeBefore: '시간까지'
    },
    cancel: {
      notCancellable:
        '대단히 죄송합니다만, 예약 완료 후 변경 및 취소가 불가능합니다.',
      freeCancellable: '교환 전 무료 취소 가능, 교환 후 취소 및 환불 불가.',
      flex: '세부적인 취소 정책을 적용합니다.',
      preview: '미리보기',
      cancelPreview: '취소 정책 미리보기',
      info: '맞춤형 설명문',
      timeSetting: '취소 날짜 설정',
      day: '{D}일',
      timeFormat: '시간{HH:MM}',
      time: '시간{T}',
      feeSetting: '취소료 설정',
      feeSettingPrompt: '취소료 설정을 선택해주세요',
      cancelPolicyPrompt: '취소 정책을 선택해주세요',
      priceReferenceSale: '가격 기준: 판매 가격',
      priceReference: '가격 기준:',
      fromClient: '여행자에게서 (%)',
      fromAgent: '여행사에게서 (%)',
      toSupplier: '공급사에게 (%)',
      paymentCancelFee: '취소료 지불',
      fromClientToAgent: '여행자에서 여행사로',
      fromAgentToLinktivity: '여행사에서 LINKTIVITY로',
      fromLinktivityToSupplier: 'LINKTIVITY에서 공급사로'
    },
    checklist: {
      notice: '주의 사항 {{index}}',
      selectNoticePrompt: '참가 전·참가 시 주의사항을 선택해 주세요.',
      uploadPDF: 'PDF 업로드',
      uploadImage: '이미지 업로드',
      googleMapSetting: 'Google Map 설정'
    },
    faq: {
      question: '질문',
      answer: '답변',
      faqTitle: '항목 {{index}}'
    },
    itinerary: {
      title: '제목',
      detailedSchedule: '상세 일정'
    },
    startTime: {
      id: 'ID',
      name: '시작 시간',
      title: '제목',
      poolId: '재고 ID'
    },
    location: {
      title: '{{label}} 이름',
      address: '주소',
      placeId: '장소 ID',
      latlong: '위도 경도',
      select: '{{label}} 선택',
      errMsg:
        '검색 대상이 없습니다. 입력 내용을 다시 확인하거나 지도에서 직접 선택해 주세요.'
    },
    price: {
      info: '가격 {{index}}',
      reservableInterval: '예약 가능 기간',
      startTime: '시작 시간',
      endTime: '종료 시간',
      unitType: '단위 종류',
      unitPrice: '판매 단가',
      soldPrice: '판매 가격',
      agentPrice: '도매 가격',
      comission: '수수료',
      price: '요금',
      accuracy: '정확도',
      rate: '환율',
      comissionRate: '표시되는 수수료율',
      comissionRateTips:
        '명세서 및 기타 문서의 환율 항목은 설정된 이 환율로 표시됩니다.',
      auto: '환율 세밀 설정',
      applicableSetting: '적용 일자 설정',
      applicableInterval: '적용 기간',
      applicableDates: '적용 요일',
      otherApplicableDates: '기타 적용일',
      unitRange: '단위 범위',
      standardPrice: '표준 가격',
      numberIncluded: '인원 수에 포함',
      soloPurchase: '단독 구매'
    },
    voucher: {
      incrementDate: '연간 증분 기준일'
    },
    backendBundle: {
      backendOption: '백엔드',
      bundleOption: '번들',
      backendId: '백엔드 재고 ID',
      bundleChildAllotmentPoolId: '관련 자상품 재고 ID',
      parentStartTimeId: '시작 시간 재고 ID',
      refTime: '참조 시간',
      childStartTimeId: '자상품 시작 시간 재고 ID',
      addPrompt: '관련 자상품 ID 추가',
      label: '백엔드 선택',
      bundleStartTimeSetting: '시작 시간 ID 설정',
      segmentTime: '시작 시간 ID',
      childSegmentTimeId: '자상품 시작 시간 ID',
      backendAllotment: '백엔드 재고 설정',
      textPlaceholder: 'ID를 선택해주세요',
      bundleAddPrompt: '번들을 위한 재고 설정 추가',
      bundleGroupHeaderText: '관련 자상품 ID'
    },
    backendForm: {
      cloudSchedulerJob: '동기화 ID',
      state: '상태',
      status: '현황',
      lastExecution: '마지막 업데이트',
      nextExecution: '다음 업데이트',
      selectSupplier: '공급자 선택',
      selectSupplierPlaceholder: '공급사를 선택해주세요',
      name: '관리명',
      backendPoolId: '백엔드 재고 관리 ID',
      backend: '백엔드 정보',
      selectBackendPlaceholder: '연결할 백엔드를 선택해주세요',
      supplierPoolId: '공급자 그룹 ID',
      scheduleTimezone: '업데이트 시간대',
      schedule: '업데이트 일정',
      scheduleNote: '*기입 방법은 여기를 참고해주세요'
    },
    allotmentForm: {
      supplierId: '공급사명',
      selectSupplierPlaceholder: '공급사를 선택해주세요',
      id: '재고 ID',
      name: '재고 관리명',
      freesale: '자유 판매',
      copyCalendarEdits: '캘린더 편집 복사'
    },
    dateSetting: {
      titlePlaceholder: '재고 관리명을 입력하세요',
      name: '기본 재고 설정',
      interval: '적용 기간（요일）',
      exactDates: '개별 적용일',
      commonAllotment: '재고 설정',
      add: '기본 재고 추가',
      common: '기본값',
      freesale: '자유 판매',
      createTitle: '재고 설정 추가',
      modal: {
        interval: '적용 기간',
        dates: '적용 요일',
        otaAllotments: '여행사 재고 설정'
      }
    },
    exception: {
      name: '예외 시작 시간 설정',
      default: '기본 재고',
      allowedDefault: '사용 가능',
      disallowedDefault: '사용 불가',
      defaultApplicable: '(기본 재고 설정 적용: {{applicable}})',
      applicable: '적용',
      notApplicable: '적용하지 않음'
    },
    segmentId: {
      name: '시작 시간 ID',
      addPrompt: '시작 시간 ID 추가',
      refTime: '참조 시간',
      startTimeIdRequired: '시작 시간 ID를 입력하세요',
      startTimeRequired: '시작 시간을 입력하세요'
    },
    otaGroup: {
      name: '여행사 재고 그룹 설정',
      groupName: '여행사 재고 그룹명',
      addPrompt: '대리점 재고 설정 추가',
      addOta: '여행사 추가',
      deleteOta: '여행사 삭제'
    },
    calendar: {
      groupRule: '재고 표시',
      week: '주',
      month: '월',
      showMore: '자세히',
      amountReserved: '실제 예약 수',
      amountLimit: '최대 예약 수',
      amountBackend: '공급자 재고'
    },
    editAllotment: {
      type: '종류',
      amountReserved: '예약 완료',
      amountEdited: '재고 할당량',
      amountRemains: '남은 재고',
      shared: '공유 재고'
    }
  },
  common: {
    account: '계정',
    signOut: '로그아웃',
    no: '아니오',
    yes: '예',
    add: '추가',
    or: '또는',
    edit: '편집',
    register: '등록',
    save: '저장',
    on: '켜짐',
    off: '꺼짐',
    cancel: '취소',
    confirm: '확인',
    colon: ':'
  },
  lang: {
    en: '영어',
    ja: '일본어',
    zh: '중국어(간체)',
    zt: '중국어(번체)',
    ko: '한국어'
  },
  layouts: {
    sidebar: {
      dashboard: '대시보드',
      activitiesManagement: '상품 관리',
      activities: '상품 목록',
      settings: '설정',
      info: '기본 정보',
      detail: '상세 정보',
      agent: '가격 그룹 설정',
      i18n: '다국어 설정',
      planInfo: '플랜 - {{title}}',
      booking: '예약 정보 설정',
      price: '단위/가격 설정',
      addPlan: '플랜 추가',
      planName: '플랜명',
      duplicatePlan: '플랜 복제',
      adminSetting: '관리자 설정',
      adminPlanInfo: '플랜 - {{title}} 설정',
      adminActivity: '상품 설정',
      adminPlan: '플랜 설정',
      inventory: '재고 설정',
      backendInventory: '백엔드 재고 설정',
      editMemo: '메모 편집',
      editLog: '편집 기록',
      allotmentSetting: '운영일 및 재고 설정',
      allotmentSegmentId: '시작 시간 ID 추가',
      allotmentException: '예외 시작 시간 설정',
      allotmentCalendar: '달력 편집',
      allotmentOtaGroup: '판매대리점 그룹 재고 설정',
      allotmentBackend: '백엔드 설정',
      backendCalendar: '달력'
    },
    relatedLinks: {
      links: '관련 링크'
    }
  },
  "firebase['auth/wrong-password']": '로그인명 또는 비밀번호가 잘못되었습니다.',
  "firebase['auth/user-not-found']": '로그인명 또는 비밀번호가 잘못되었습니다.',
  "firebase['auth/email-already-in-use']":
    '이메일 주소가 이미 등록되어 있습니다.',
  "firebase['auth/too-many-requests']":
    '로그인 시도가 너무 많습니다. 잠시 후 다시 시도하거나 비밀번호를 재설정해 주세요.',
  validation: {
    required: '{{name}}을 입력해 주세요.',
    requiredSelect: '선택해 주세요.',
    invalid: '유효한 {{name}}을 입력해 주세요.',
    password: '문자와 숫자가 결합된 8자 이상의 비밀번호를 설정해 주세요.'
  },
  views: {
    common: {
      activityId: '상품ID',
      yes: '있음',
      no: '없음',
      edit: '편집',
      close: '닫기',
      editConfirm: '편집 확인',
      successCreatedMsg: '{{name}} 추가에 성공했습니다.',
      errorCreatedMsg: '{{name}} 추가에 실패했습니다.',
      successDeletedMsg: '{{name}} 삭제에 성공했습니다.',
      errorDeletedMsg: '{{name}} 삭제에 실패했습니다.',
      successUpdatedMsg: '{{name}} 업데이트에 성공했습니다.',
      errorUpdatedMsg: '{{name}} 업데이트에 실패했습니다.'
    },
    dashboard: '대시보드',
    activities: {
      name: '상품명',
      title: '상품 제목',
      edit: '편집',
      confirmed: '상품 확인 성공',
      status: '현황',
      list: '상품 목록',
      addActivity: '상품 등록',
      addNewActivity: '새 상품 등록',
      add: '등록',
      selectSupplier: '공급사를 선택해 주세요.',
      inputSupplierTitle: '상품명을 입력해 주세요.',
      copyTitle: '상품 복사',
      selectSupplierLabel: '공급사 선택',
      copy: '복사'
    },
    allotmentSettings: {
      pageTitle: '운영일 및 재고 설정',
      title: '재고 설정',
      management: '재고 등록',
      poolId: '관리 ID',
      supplierId: '공급사명',
      name: '재고 관리명',
      sharedAllotment: '공유 재고',
      inputAllotmentName: '재고 관리명을 입력해 주세요.',
      leaveCurrentPageConfirm:
        '재고 설정이 아직 편집 중입니다. 이 페이지를 떠나도 괜찮습니까?',
      leaveCurrentPage: '이 페이지를 떠납니다',
      continueEditing: '편집을 계속합니다',
      edit: {
        name: '재고',
        createTitle: '새 재고 등록',
        copyTitle: '재고 복사'
      }
    },
    backendSettings: {
      title: '백엔드 재고 설정',
      newTitle: '새 백엔드 재고 설정',
      management: '재고 등록',
      poolId: '관리 ID',
      supplierId: '공급사명',
      name: '관리명',
      confirm: '확정',
      state: '상태',
      status: '현황',
      lastExecution: '마지막 업데이트',
      nextExecution: '다음 업데이트',
      sharedAllotment: '공유 재고',
      jobStatus: {
        success: '성공',
        failed: '실패'
      }
    },
    activity: {
      log: {
        logHistory: '상품 편집 이력',
        priceHistory: '가격 그룹 편집 이력',
        chooseLog: '이력 선택',
        chooseItemLabel: '플랜 {{index}} 편집 이력',
        html: 'HTML Log',
        by: 'by'
      },
      memo: {
        title: '편집용 메모',
        tips: '편집용 메모는 상품 편집 시 기록용으로 사용되며, 대리점 또는 직판 사이트에서는 표시되지 않습니다.',
        successUpdated: '메모가 저장되었습니다.'
      },
      tag: {
        title: '편집용 태그',
        tips: '편집용 태그는 상품 편집 시 기록용으로 사용되며, 대리점 또는 직판 사이트에서는 표시되지 않습니다.'
      },
      paymentCollector: '수금자',
      productDescription: '상품 설명',
      priceSetting: '통화 설정',
      autoPublic: '자동 공개 설정',
      autoPublicGroup: '\b대상 그룹 선택',
      imageSetting: '이미지 설정',
      participantNotice: '참여 전・참여 시 주의 사항 (포함되는 항목 등)',
      agentGroupName: '가격 그룹명',
      agentSaved: '대리점을 저장했습니다',
      agentSetting: '가격 그룹 설정',
      addAgentGroup: '가격 그룹 추가',
      agentTips:
        '※가격 그룹은 대리점별로 가격 설정과 연동되므로, 하나의 여행 대리점을 여러 그룹에 추가할 수 없습니다.\n가격 그룹: 하나의 대리점에 대해 한 가지 가격 설정과 연동하기 위해, 하나의 대리점을 여러 그룹에 추가할 수 없습니다.',
      comment: '코멘트',
      publishedApplied: '공개 신청했습니다.',
      rejectApplied: '반려했습니다.',
      approved: '승인했습니다.',
      published: '공개했습니다.',
      publish: '공개',
      reject: '반려',
      approve: '공개 \b승인',
      submit: '공개 신청',
      save: '저장',
      preview: '미리보기',
      applyForPublish: '상품 공개 신청',
      confirmReject: '승인 반려',
      confirmApprovalOfProduct: '상품 공개 승인',
      productContentConfirmed: '상품 내용을 확인했습니다.',
      confirmForPublishingProducts: '상품 공개 확인',
      productPublishedConfirmed: '상품 공개를 승인했습니다.',
      productSaved: '상품을 저장했습니다.',
      languageSetting: '다언어 설정',
      languageSelection: '언어 선택',
      productTitle: '상품명 (바우처 제목)',
      origin: '출발지',
      destination: '목적지',
      checkinPoints: '집합 장소',
      checkoutPoints: '해산 장소',
      pickupLocation: '픽업 장소',
      dropoffLocations: '샌딩 장소',
      cancellationPolicy: '취소 정책',
      faqSetting: 'FAQ 설정',
      chosenLanguage: '게재 언어',
      timeZone: '국가·지역 (시간대)',
      category: '카테고리',
      useQrCode: 'QR 코드 사용',
      qrcodeType: 'QR 코드 유형',
      voucherSetting: '바우처 설정',
      appointmentSetting: '예약 설정',
      appointmentNotification: '예약 통지',
      useEticketItems: 'E-TICKET 이용 항목',
      qrcodeTemplate: 'QR 코드 템플릿',
      qrcodeHeader: 'QR 코드 헤더',
      qrcodeFooter: 'QR 코드 푸터',
      qrcodeGenerator: 'QR 코드 생성기',
      redemptionUrlTemplate: '교환 URL 템플릿',
      adminSettingSaved: 'Admin 설정을 저장했습니다',
      allotmentSettingSaved: '플랜 설정을 저장했습니다',
      planSelection: '플랜 선택',
      planId: '플랜 ID',
      systemCharge: '시스템 요금',
      productInfo: '상품 정보',
      supplierId: '공급사 ID',
      supplierName: '공급사명',
      name: '상품명',
      editMemo: '편집용 메모',
      editTag: '편집용 태그',
      publishActivityConfirm: '이 상품을 공개하시겠습니까?',
      publishActivitySuccess:
        '※ 상품이 공개된 후, 등록된 여행 대리점에서 상품 정보를 열람할 수 있습니다.',
      inPublish: '공개 중',
      isActivityPublished: '상품을 공개했습니다.',
      isActivityPublishedFailed: '상품 공개에 실패했습니다.',
      contactUs: '죄송합니다만, 담당자에게 연락해 주시기 바랍니다.',
      leaveCurrentPage: '이 페이지를 떠나기',
      continueEditing: '편집을 계속하기',
      salesStatus: '상품 표시',
      hideFromSearch: '목록 표시',
      salesStatusNotes:
        '※상품의 표시 상태를 변경하려면 다시 공개해야 하니 주의해 주시기 바랍니다.',
      hideFromSearchNotes:
        '※목록 표시를 끄면 검색 결과나 상품 목록 화면에는 표시되지 않으니 주의해 주시기 바랍니다.',
      qrCodeTips:
        "※ '인원수만큼 QR 코드 발행' 또는 '모기리 이용' 설정은 당사 담당자에게 문의해 주세요."
    },
    plan: {
      saveConfirm: '플랜이 저장되었습니다.',
      name: '플랜명',
      save: '저장',
      planPublish: '플랜 게재 표시',
      bookingRule: '예약 설정',
      voucherExpiration: '바우처 유효 기간',
      expirationVisibilitySetting: '바우처 유효 기간을 비공개로 설정하기',
      visibilitySetting: {
        hideFromVoucher: '바우처에 비공개',
        hideFromActivityDetails: '웹페이지에 비공개 (상품 내용)',
        hideFromBookingDetails: '웹페이지에 비공개 (예약 상세)'
      },
      checkinPoint: '\b집합 장소',
      checkoutPoint: '해산 장소',
      pickupLocation: '픽업 장소',
      dropoffLocation: '하차 장소',
      unitSetting: '유닛 설정',
      priceSetting: '요금 설정',
      bookingAgentRequiredInfo: '대표자 정보 - 예약별 (필수)',
      bookingAgentRecommendedInfo: '대표자 정보 - 예약별 (선택)',
      bookingOtherRequiredInfo: '기타 정보 - 예약별 (필수)',
      bookingOtherRecommendedInfo: '기타 정보 - 예약별 (선택)',
      participantRequiredInfo: '이용자 정보 - 참가자별 (필수)',
      participantRecommendedInfo: '이용자 정보 - 참가자별 (선택)',
      customBookingInfo: '맞춤형 예약 정보',
      settingUnable: '종료일을 시작일 이전으로 설정할 수 없습니다.',
      description: '플랜 설명',
      imageSetting: '이미지 설정',
      availablePeriod: '공개 기간',
      reservationRestrict: '예약 수량 제한',
      pieces: '장',
      min: '최소',
      max: '최대',
      tilde: '〜',
      checklistLabel: '참가 전・참가 시 주의사항(포함 항목 등)',
      startTime: '시작 시간',
      setSchedule: '일정 설정',
      editConfirm: '편집 확인',
      leave: '이 페이지를 떠나기',
      continueEditing: '편집 계속하기',
      code: {
        id: '플랜 ID',
        coupon: 'Bundle 자상품의 바우처를 비공개로 설정하기',
        backendId: '재고 설정 ID',
        backendIdLabel: '재고 설정을 선택해주세요',
        goToSetting: '미설정인 경우에는 <0>여기</0>에서 설정해주세요',
        backend: 'Backend 정보',
        backendLabel: '연동할 Backend를 선택해주세요',
        productCode: 'Product Code',
        productCodeLabel: 'Product Code 입력해주세요',
        unit: 'Unit 설정',
        unitType: '단위 종류',
        unitRange: '단위 범위',
        unitCode: 'Unit Code',
        startTimeTitle: '시작 시간 설정',
        startTime: '\b시작 시간',
        startTimeCode: 'Start time Code',
        codeLabel: 'Code를 입력해주세요'
      },
      planPublishNotes:
        '※ 플랜의 표시 상태를 변경하려면 상품을 다시 공개해야 하므로, 주의해 주세요.',
      leavePrompt: '플랜이 아직 수정 중입니다. 이 페이지를 떠나시겠습니까?'
    },
    login: {
      title: 'Welcome to GDS',
      email: '이메일 주소',
      password: '비밀번호',
      signIn: '로그인',
      forgotPassword: '비밀번호를 잊으셨나요?',
      googleLogin: 'Google로 로그인'
    },
    password: {
      changePassword: '비밀번호 변경',
      resetPassword: '비밀번호 재설정',
      sendResetEmail: '비밀번호 재설정 이메일 보내기',
      oldPassword: '이전 비밀번호',
      newPassword: '새로운 비밀번호',
      changePasswordSuccess: '비밀번호가 변경되었습니다.'
    }
  }
};
