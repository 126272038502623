import { action, computed, observable } from 'mobx';
import { activityApi } from '@link/gds-supplier/services';

export class TimeZone {
  @observable accessor timeZones: Array<string> = [];

  @action
  getTimeZones = () => {
    activityApi.listTimeZones().then(res => {
      res?.timeZones && this.setTimeZones(res.timeZones);
    });
  };

  @action
  setTimeZones = (timeZones: Array<string>) => {
    this.timeZones = timeZones;
  };

  @computed
  get loaded(): boolean {
    return this.getTimeZones.length > 0;
  }
}

export default new TimeZone();
