// Generated i18n file
export default {
  unit: {
    AGE_ADULT_AND_CHILD: 'Adults & Children ({n}-{m} years old)',
    AGE_ADULT: 'Adults ({n}-{m} years old)',
    AGE_YOUTH: 'Youths ({n}-{m} years old)',
    AGE_CHILD: 'Children ({n}-{m} years old)',
    AGE_SENIOR: 'Seniors ({n}-{m} years old)',
    AGE_INFANT: 'Infants ({n}-{m} years old)',
    AGE_STUDENT: 'Students ({n}-{m} years old)',
    AGE_HIGH_SCHOOL: 'High School Students ({n}-{m} years old)',
    AGE_INTERMEDIATE_SCHOOL: 'Junior High School Students ({n}-{m} years old)',
    AGE_ELEMENTALY_SCHOOL: 'Elementary School Students ({n}-{m} years old)',
    AGE_DISABLED_ADULT:
      'Persons with Disabilities - Adults ({n}-{m} years old)',
    AGE_DISABLED_YOUTH:
      'Persons with Disabilities - Youths ({n}-{m} years old)',
    AGE_DISABLED_CHILD:
      'Persons with Disabilities - Children ({n}-{m} years old)',
    AGE_SUPPORTER_ADULT: 'Caregiver (Adults)',
    AGE_SUPPORTER_CHILD: 'Caregiver (Children)',
    MINUTES_VEHICLES: '{n} Minutes',
    HOURS_VEHICLES: '{n} Hours',
    DAYS_VEHICLES: '{n} Days',
    WEEKS_VEHICLES: '{n} Weeks',
    NIGHTS_VEHICLES: '{n} Nights',
    CHARTER_VAR: '1 Vehicle ({n}-{m} people)',
    GROUP_VAR: '1 Group ({n}-{m} people)',
    ROOM_VAR: '1 Room ({n}-{m} people)',
    BOAT_VAR: '1 Boat ({n}-{m} people)',
    PET: 'Pets',
    PET_CAT: 'Cats',
    PET_DOG: 'Dogs',
    PET_DOG_S: 'Small Dogs',
    PET_DOG_M: 'Medium Dogs',
    PET_DOG_L: 'Large Dogs',
    PET_DOG_XL: 'Extra Large Dogs',
    PET_OTHER: 'Other Pets'
  },
  collectingType: {
    PER_PARTICIPANT: 'Per Participant',
    PER_BOOKING: 'Per Reservation'
  },
  collectingTiming: {
    ON_BOOKING: 'Required at the Time of Booking',
    BEFORE_ACTIVITY: 'Required on the Day of Participation',
    OPTIONAL: 'Optional'
  },
  formType: {
    DATE_ONLY_PAST: 'Date (Past Only)',
    DATE_ONLY_FUTURE: 'Date (Future Only)',
    TIME: 'Time',
    YES_NO: 'Yes/No',
    DROPDOWN: 'Dropdown Selection',
    RADIO: 'Radio Button',
    CHECK: 'Checkbox',
    TEXT: 'Text',
    TEXT_ALPHANUMERIC: 'Text (Alphanumeric)',
    TEXT_PHONE: 'Phone Number',
    TEXTAREA: 'Text Area'
  },
  formatType: {
    BEFORE_ACTIVITY_FORWARD_FIXED_CLOCK:
      'Cancellations made after {HH:MM} (local time) on {D} days before the usage/participation date will be charged a cancellation fee of {N} of the total reservation amount.',
    BEFORE_ACTIVITY_FORWARD_DURATION:
      'Cancellations made less than {T} hours before the usage/participation time will be charged a cancellation fee of {N} of the total reservation amount.',
    AFTER_BOOKING_FORWARD_DURATION:
      '{T} hours after the reservation is confirmed, a cancellation fee of {N} of the total reservation amount will be charged.',
    AFTER_BOOKING_FORWARD_FIXED_CLOCK:
      'Starting from the reservation confirmation date (Day 0), cancellations made after {HH:MM} (local time) on Day {D} will be charged a cancellation fee of {N} of the total reservation amount.',
    BEFORE_ACTIVITY_BACKWARD_FIXED_CLOCK:
      'Cancellations made by {HH:MM} (local time) on {D} days before the usage/participation date will be charged a cancellation fee of {N} of the total reservation amount.',
    BEFORE_ACTIVITY_BACKWARD_DURATION:
      'Cancellations made at least {T} hours before the usage/participation time will be charged a cancellation fee of {N} of the total reservation amount.',
    AFTER_BOOKING_BACKWARD_DURATION:
      'For cancellations made within {T} hours after the reservation is confirmed, a cancellation fee of {N}% of the total reservation amount will be charged.',
    AFTER_BOOKING_BACKWARD_FIXED_CLOCK:
      'Starting from the booking confirmation date (Day 0), cancellations made by {HH:MM} (local time) on Day {D} will be charged a cancellation fee of {N} of the total booking amount.'
  },
  confirmationType: {
    FREE_SALE: 'Participation Date Specified (Free Sale)',
    FREE_SALE_OPEN_DATE: 'Participation Date Not Required (Free Sale)',
    INVENTORY: 'Participation Date Specified (Real-Time)',
    REQUEST: 'Participation Date Required (Request)'
  },
  voucherType: {
    NONE: 'None',
    COLLECTIVE: 'QR Code by Reservation',
    INDIVIDUAL: 'QR Code by Number of Participants'
  },
  qrcodeType: {
    QRCODE: 'QRCODE',
    TEXT: 'TEXT',
    URL: 'URL',
    BARCODE_128: 'BARCODE_128',
    BARCODE_93: 'BARCODE_93',
    BARCODE_39: 'BARCODE_39',
    BARCODE_25: 'BARCODE_25'
  },
  voucherMedium: {
    ELECTRONIC: 'No printing required (on-screen display)',
    PAPER: 'Printing required'
  },
  cancellationFeeType: {
    PERCENTAGE: 'Percentage',
    ADVANCED_PERCENTAGE: 'Advanced Percentage Settings',
    FIXED: 'Fixed Fee',
    NOT_CANCELLABLE: 'Non-cancellable'
  },
  datePeriodUnit: {
    DAY: 'Daily calculation',
    MONTH: 'Monthly calculation'
  },
  basePrice: {
    GROSS: 'Gross Price',
    NET: 'Net Price',
    SUPPLIER_NET: 'Supplier Net Price'
  },
  checklistType: {
    RESTRICTION: 'Participation Restrictions',
    REQUIRED_ITEM_TO_BRING_AND_ATTIRE:
      'Required clothing and items for participation',
    REQUIRED_OTHER: 'Other required things for participation',
    REQUIRED_ADDITIONAL_NOTE: 'Additional Notes and Other',
    NICE_TO_HAVE_ITEM_TO_BRING_AND_ATTIRE: 'Recommended clothing and items',
    NICE_TO_HAVE_OTHER: 'Other recommendations',
    INCLUDED: 'Included Items'
  },
  week: {
    SUNDAY: 'Sunday',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday'
  },
  category: {
    RAILWAY_TICKET: 'Train Ticket',
    AIRPORT_TRANSPORTATION: 'Airport Transportation',
    SHINKANSEN: 'Shinkansen',
    EXPRESS_BUS: 'Express Bus',
    BUS_TOUR: 'Bus Tour',
    CRUISES: 'Ferries and Cruises',
    RENTAL_CAR: 'Rental Car',
    CHARTER: 'Charter',
    ATTRACTION: 'Attraction (Admission Ticket)',
    THEME_PARK: 'Theme Park',
    MUSEUM_GALLERY: 'Museum and Gallery',
    AQUARIUM_ZOO: 'Aquarium and Zoo',
    TEMPLES_SHINES: 'Temples and Shrines',
    TOWER_BUILDING: 'Tower and Observation Deck',
    JAPANESE_RESTAURANT: 'Japanese Restaurant',
    WESTERN_RESTAURANT: 'Western Restaurant',
    CHINESE_RESTAURANT: 'Chinese Restaurant',
    OTHER_RESTAURANT: 'Other Restaurants',
    DESSERT_BEVERAGE: 'Dessert and Beverages',
    SIGHTSEEING_TOUR: 'Sightseeing Tour',
    MARINE_SPORTS: 'Marine Sports',
    OUTDOOR_ACTIVITIES: 'Outdoor Activities',
    ONSEN: 'Onsen',
    SPA_MASSAGE: 'Spa and Massage',
    JAPANESE_CULTURAL_EXPERIENCE: 'Japanese Cultural Experience',
    JAPANESE_CRAFT_EXPERIENCE: 'Japanese Craft Experience',
    JAPANESE_COOKING_EXPERIENCE: 'Japanese Cooking Experience',
    OTHER_EXPERIENCE: 'Other Experiences',
    HOTEL: 'Hotel',
    RYOKAN: 'Ryokan (traditional Japanese inn)',
    VACATION_RENTAL: 'Minpaku (private home or vacation rental)',
    BUNDLE: 'Bundle',
    PASS: 'Pass',
    OTHERS: 'Others'
  },
  bookingField: {
    PARTICIPANT_EMAIL_ADDRESS: 'Participant’s Email Address',
    HOTEL_NAME: 'Accommodation Name',
    HOTEL_ADDRESS: 'Accommodation Address',
    HOTEL_RESERVATION_LAST_NAME: 'Accommodation Representative’s Last Name',
    HOTEL_RESERVATION_FIRST_NAME: 'Accommodation Representative’s First Name',
    HOTEL_TEL: 'Accommodation Phone Number',
    ARRIVAL_DATE: 'Arrival Date',
    ARRIVAL_TIME: 'Arrival Time',
    ARRIVAL_FLIGHT_NUMBER: 'Arrival Flight Number',
    DEPARTURE_DATE: 'Departure Date',
    DEPARTURE_TIME: 'Departure Time',
    DEPARTURE_FLIGHT_NUMBER: 'Departure Flight Number',
    DESTINATION_EMAIL: 'Local Contact Email Address',
    DESTINATION_TEL: 'Local Contact Phone Number',
    PARTICIPANT_RESIDENT_REGION: 'Participant’s Country/Region of Residence',
    PARTICIPANT_LANGUAGE: 'Participant’s Language'
  },
  extendedBookingField: {
    PASSENGERS_QUANTITY: 'Number of Passengers',
    PREFERRED_START_TIME: 'Preferred Start Time',
    PREFERRED_PICK_UP_LOCATION: 'Preferred Pick-Up Location',
    PREFERRED_DROP_OFF_LOCATION: 'Preferred Drop-Off Location',
    PARTICIPANTS_QUANTITY: 'Number of Participants',
    LUGGAGE_QUANTITY: 'Total number of suitcases',
    CHILDREN_WITHOUT_SEAT_QUANTITY: 'Infants Without Seat Quantity',
    CHILD_SEAT_QUANTITY_PAID: 'Use of child seat (pay locally)',
    CHILD_SEAT_QUANTITY_FREE: 'Use of child seat (free of charge)',
    PREFERRED_GUIDE_LANGUAGE_CODE: 'Preferred Guide Language',
    PREFERRED_DRIVER_LANGUAGE_CODE: 'Preferred Driver Language'
  },
  extendedParticipantField: {
    PASSPORT_FIRST_NAME:
      "Participant's First Name (as per passport in Roman letters)",
    PASSPORT_LAST_NAME:
      "Participant's Last Name (as per passport in Roman letters)",
    PASSPORT_NATIONALITY: 'Nationality',
    PASSPORT_NUMBER: 'Passport Number',
    DATE_OF_BIRTH: 'Date of Birth',
    AGE: 'Age',
    GENDER: 'Gender',
    EYESIGHT: 'Vision',
    WEIGHT_KG: 'Weight (kg)',
    HEIGHT_CM: 'Height (cm)',
    SHOE_SIZE_CM: 'Shoe Size (cm)',
    CLOTHES_SIZE: 'Clothes Size',
    VEGETARIAN_MEALS: 'Vegetarian Food Request',
    DIVING_EXPERIENCE_TIMES: 'Diving Experience Times',
    RENTAL_EQUIPMENTS_FREE: 'Rental Equipment Request (free of charge)',
    RENTAL_EQUIPMENTS_PAY_LOCALLY: 'Rental Equipment Request (pay locally)'
  },
  templateStatus: {
    DRAFT: 'Draft',
    REVIEWING: 'Under Review',
    APPROVED: 'Approved',
    PUBLISHED: 'Published'
  },
  universalType: {
    TRUE: 'Common to All Plans',
    FALSE: 'Different for Each Plan'
  },
  datePeriod: {
    DAY: 'Days',
    MONTH: 'Months',
    tilde: '~'
  },
  voucherExpirationType: {
    FROM_PURCHASE_DATE: 'From the Purchase Date',
    FROM_ACTIVITY_DATE: 'From the Activity Date',
    FIXED_DATE: 'Fixed Date',
    RELATIVE_FIXED_DATE: 'Relative Fixed Date'
  },
  operation: {
    CREATE: 'Product Created',
    UPDATE: 'Edited',
    DELETE: 'Deleted',
    SUBMIT_FOR_REVIEW: 'Submitted for Review',
    REJECT: 'Rejected',
    APPROVE: 'Publication request approved',
    PUBLISH: 'Published'
  },
  jobState: {
    ENABLED: 'Success',
    PAUSED: 'Stop',
    DISABLED: 'Invalid',
    UPDATE_FAILED: 'Update Failed',
    ERROR: 'Error'
  },
  salesStatus: {
    ON: 'Normal',
    OFF: 'Archived'
  }
};
