// Generated i18n file
export default {
  common: {
    delete: '삭제',
    updateSuccess: '업데이트 완료',
    sync: '동기화'
  },
  confirmDelete: {
    title: '확인해 주세요',
    prompt: '정말 삭제하시겠습니까?'
  },
  supplierSelect: {
    selectSupplier: '공급사를 선택해 주세요'
  },
  agentGroup: {
    addAgent: '여행사 추가',
    deleteAgent: '여행사 삭제'
  }
};
