// Generated i18n file
export default {
  component: {
    form: {
      id: '預約資訊ID',
      title: '預約資訊標題',
      info: '預約資訊說明',
      setting: '預約資訊設定方式',
      deadline: '截止類型',
      answerType: '回答形式',
      selectItems: '選擇項目',
      selectPrompt: '請選擇'
    },
    rule: {
      type: '預約類型',
      possibleInterval: '可預約期間',
      interval: '預約截止時間',
      daysBefore: '天前',
      timeBefore: '直到時間'
    },
    cancel: {
      notCancellable: '預約後不接受取消或更改。',
      freeCancellable: '換票前可免費取消，換票後不予退款。',
      flex: '設定高級取消政策',
      preview: '預覽',
      cancelPreview: '取消政策預覽',
      info: '自定義說明文',
      timeSetting: '取消日期設定',
      day: '第{D}天',
      timeFormat: '時間{HH:MM}',
      time: '時間{T}',
      feeSetting: '取消費用設定',
      feeSettingPrompt: '請選擇取消費用設定',
      cancelPolicyPrompt: '請選擇取消政策',
      priceReferenceSale: '價格基準：銷售價格',
      priceReference: '價格基準：',
      fromClient: '旅行者來源（%）',
      fromAgent: '旅行代理商來源（%）',
      toSupplier: '供應商來源（%）',
      paymentCancelFee: '取消費用支付',
      fromClientToAgent: '旅行者到代理商',
      fromAgentToLinktivity: '代理商到LINKTIVITY',
      fromLinktivityToSupplier: 'LINKTIVITY到供應商'
    },
    checklist: {
      notice: '注意事項 {{index}}',
      selectNoticePrompt: '請選擇參加前及參加中的注意事項',
      uploadPDF: '上傳PDF',
      uploadImage: '上傳圖片',
      googleMapSetting: 'Google Map設置'
    },
    faq: {
      question: '問題',
      answer: '回答',
      faqTitle: '項目 {{index}}'
    },
    itinerary: {
      title: '標題',
      detailedSchedule: '詳細行程'
    },
    startTime: {
      id: 'ID',
      name: '開始時間',
      title: '標題',
      poolId: '庫存 ID'
    },
    location: {
      title: '{{label}} 名稱',
      address: '地址',
      placeId: '地點 ID',
      latlong: '緯度經度',
      select: '選擇 {{label}}',
      errMsg: '搜尋目標無法找到。請檢查輸入內容或直接從地圖選擇。'
    },
    price: {
      info: '價格 {{index}}',
      reservableInterval: '可預約期間',
      startTime: '開始時間',
      endTime: '結束時間',
      unitType: '單位種類',
      unitPrice: '單位價格',
      soldPrice: '銷售價格',
      agentPrice: '批發價格',
      comission: '佣金',
      price: '價格',
      accuracy: '精確度',
      rate: '匯率',
      comissionRate: '顯示佣金率',
      comissionRateTips: '該匯率將顯示在明細或報表中。',
      auto: '匯率精度設定',
      applicableSetting: '適用日期設定',
      applicableInterval: '適用期間',
      applicableDates: '適用星期',
      otherApplicableDates: '其他適用日',
      unitRange: '單位範圍',
      standardPrice: '標準價格',
      numberIncluded: '定員包含',
      soloPurchase: '單獨購買'
    },
    voucher: {
      incrementDate: '年度增分基準日'
    },
    backendBundle: {
      backendOption: 'Backend',
      bundleOption: '套餐',
      backendId: 'Backend庫存ID',
      bundleChildAllotmentPoolId: '相關子商品庫存ID',
      parentStartTimeId: '開始時間庫存ID',
      refTime: '參考時間',
      childStartTimeId: '子商品開始時間庫存ID',
      addPrompt: '添加相關子商品ID',
      label: '選擇Backend',
      bundleStartTimeSetting: '設定開始時間ID',
      segmentTime: '開始時間ID',
      childSegmentTimeId: '子商品開始時間ID',
      backendAllotment: 'Backend庫存設定',
      textPlaceholder: '請選擇ID',
      bundleAddPrompt: '為Bundle添加庫存設定',
      bundleGroupHeaderText: '相關子商品ID'
    },
    backendForm: {
      cloudSchedulerJob: '同步ID',
      state: '狀態',
      status: '狀態',
      lastExecution: '上次更新',
      nextExecution: '下一次更新',
      selectSupplier: '選擇供應商',
      selectSupplierPlaceholder: '請選擇供應商',
      name: '管理名稱',
      backendPoolId: 'Backend庫存管理ID',
      backend: 'Backend資訊',
      selectBackendPlaceholder: '請選擇連接的Backend',
      supplierPoolId: '供應商池ID',
      scheduleTimezone: '更新時區',
      schedule: '更新計劃',
      scheduleNote: '請參考此處的說明方法'
    },
    allotmentForm: {
      supplierId: '供應商名稱',
      selectSupplierPlaceholder: '請選擇供應商',
      id: '庫存ID',
      name: '庫存管理名稱',
      freesale: '自由銷售',
      copyCalendarEdits: '複製日曆編輯'
    },
    dateSetting: {
      titlePlaceholder: '請輸入庫存管理名稱',
      name: '基本庫存設置',
      interval: '適用期間（星期幾）',
      exactDates: '個別適用日',
      commonAllotment: '庫存設置',
      add: '添加基本庫存',
      common: '共用',
      freesale: '自由銷售',
      createTitle: '添加庫存設置',
      modal: {
        interval: '適用期間',
        dates: '適用星期',
        otaAllotments: '代理商庫存設置'
      }
    },
    exception: {
      name: '例外開始時間設置',
      default: '基本庫存',
      allowedDefault: '可用',
      disallowedDefault: '不可用',
      defaultApplicable: '（基本庫存設置適用：{{applicable}}）',
      applicable: '適用',
      notApplicable: '不適用'
    },
    segmentId: {
      name: '開始時間ID',
      addPrompt: '添加開始時間ID',
      refTime: '參考時間',
      startTimeIdRequired: '請輸入開始時間ID',
      startTimeRequired: '請輸入開始時間'
    },
    otaGroup: {
      name: '代理商庫存組設置',
      groupName: '代理商庫存組名稱',
      addPrompt: '添加代理商庫存設置',
      addOta: '添加代理商',
      deleteOta: '刪除代理商'
    },
    calendar: {
      groupRule: '顯示庫存',
      week: '週',
      month: '月',
      showMore: '詳情',
      amountReserved: '實際預約數量',
      amountLimit: '最大預約數量',
      amountBackend: '供應商庫存'
    },
    editAllotment: {
      type: '類型',
      amountReserved: '已預定',
      amountEdited: '庫存配額',
      amountRemains: '剩餘庫存',
      shared: '共享庫存'
    }
  },
  common: {
    account: '帳號',
    signOut: '登出',
    no: '否',
    yes: '是',
    add: '添加',
    or: '或者',
    edit: '編輯',
    register: '註冊',
    save: '保存',
    on: '開啟',
    off: '關閉',
    cancel: '取消',
    confirm: '確認',
    colon: '：'
  },
  lang: {
    en: '英語',
    ja: '日文',
    zh: '簡體中文',
    zt: '繁體中文',
    ko: '韓文'
  },
  layouts: {
    sidebar: {
      dashboard: '儀表板',
      activitiesManagement: '產品管理',
      activities: '產品列表',
      settings: '設定',
      info: '基本信息',
      detail: '詳細信息',
      agent: '價格組設定',
      i18n: '多語言設置',
      planInfo: '計劃 - {{title}}',
      booking: '預約信息設置',
      price: '單位/價格設置',
      addPlan: '添加計劃',
      planName: '計劃名稱',
      duplicatePlan: '複製計劃',
      adminSetting: '管理設定',
      adminPlanInfo: '計劃 - {{title}} 設置',
      adminActivity: '產品設置',
      adminPlan: '計劃設置',
      inventory: '庫存設置',
      backendInventory: 'Backend庫存設置',
      editMemo: '編輯備註',
      editLog: '編輯歷史',
      allotmentSetting: '營業日與庫存設置',
      allotmentSegmentId: '添加開始時間ID',
      allotmentException: '例外開始時間設置',
      allotmentCalendar: '編輯日曆',
      allotmentOtaGroup: '代理商庫存組設定',
      allotmentBackend: 'Backend設置',
      backendCalendar: '日曆'
    },
    relatedLinks: {
      links: '相關連結'
    }
  },
  "firebase['auth/wrong-password']": '登入名稱或密碼錯誤',
  "firebase['auth/user-not-found']": '登入名稱或密碼錯誤',
  "firebase['auth/email-already-in-use']": '郵箱地址已經註冊',
  "firebase['auth/too-many-requests']":
    '登入嘗試次數過多。請稍後再試或重設密碼。',
  validation: {
    required: '請輸入{{name}}',
    requiredSelect: '請選擇',
    invalid: '請輸入有效的{{name}}',
    password: '請設置包含字母和數字的8個字符以上密碼'
  },
  views: {
    common: {
      activityId: '產品ID',
      yes: '有',
      no: '沒有',
      edit: '編輯',
      close: '關閉',
      editConfirm: '確認編輯',
      successCreatedMsg: '成功添加{{name}}',
      errorCreatedMsg: '添加{{name}}失敗',
      successDeletedMsg: '成功刪除{{name}}',
      errorDeletedMsg: '刪除{{name}}失敗',
      successUpdatedMsg: '成功更新{{name}}',
      errorUpdatedMsg: '更新{{name}}失敗'
    },
    dashboard: '儀表板',
    activities: {
      name: '產品名稱',
      title: '產品標題',
      edit: '編輯',
      confirmed: '商品確認成功',
      status: '狀態',
      list: '產品列表',
      addActivity: '註冊商品',
      addNewActivity: '新商品註冊',
      add: '添加',
      selectSupplier: '選擇供應商',
      inputSupplierTitle: '請輸入商品名稱',
      copyTitle: '商品複製',
      selectSupplierLabel: '選擇供應商',
      copy: '複製'
    },
    allotmentSettings: {
      pageTitle: '營業日與庫存設置',
      title: '庫存設置',
      management: '庫存註冊',
      poolId: '管理ID',
      supplierId: '供應商名稱',
      name: '庫存管理名稱',
      sharedAllotment: '共享庫存',
      inputAllotmentName: '請輸入庫存管理名稱',
      leaveCurrentPageConfirm: '庫存設置仍在編輯中，確定要離開此頁面嗎？',
      leaveCurrentPage: '離開此頁面',
      continueEditing: '繼續編輯',
      edit: {
        name: '庫存',
        createTitle: '新增庫存',
        copyTitle: '複製庫存'
      }
    },
    backendSettings: {
      title: 'Backend庫存設置',
      newTitle: '新增Backend庫存設置',
      management: '庫存註冊',
      poolId: '管理ID',
      supplierId: '供應商名稱',
      name: '管理名稱',
      confirm: '確定',
      state: '狀態',
      status: '狀態',
      lastExecution: '上次更新',
      nextExecution: '下一次更新',
      sharedAllotment: '共享庫存',
      jobStatus: {
        success: '成功',
        failed: '失敗'
      }
    },
    activity: {
      log: {
        logHistory: '商品編輯歷史',
        priceHistory: '價格組別編輯歷史',
        chooseLog: '選擇編輯歷史',
        chooseItemLabel: '方案 {{index}} 編輯歷史',
        html: 'HTML Log',
        by: 'by'
      },
      memo: {
        title: '編輯筆記',
        tips: '編輯筆記僅供編輯商品時的備註目的，不會顯示在代理商或直銷網站頁面。',
        successUpdated: '已保存筆記'
      },
      tag: {
        title: '編輯標籤',
        tips: '編輯標籤僅供編輯商品時的記錄目的，不會顯示在代理商或直銷網站頁面。'
      },
      paymentCollector: '收款人',
      productDescription: '商品說明',
      priceSetting: '幣別設置',
      autoPublic: '自動發布設置',
      autoPublicGroup: '請選擇對象組別',
      imageSetting: '圖像設置',
      participantNotice: '參加前・參加時注意事項（費用包含等）',
      agentGroupName: '價格組別名稱',
      agentSaved: '已保存代理商',
      agentSetting: '價格組別設置',
      addAgentGroup: '添加價格組別',
      agentTips:
        '※因價格組別會與各個代理商聯合，不可將同代理商添加至複數組別。',
      comment: '留言',
      publishedApplied: '已提出發布申請。',
      rejectApplied: '已駁回申請。',
      approved: '已批准。',
      published: '已發布。',
      publish: '發布',
      reject: '駁回',
      approve: '發布批准',
      submit: '發布申請',
      save: '保存',
      preview: '預覽',
      applyForPublish: '商品發布申請',
      confirmReject: '駁回批准',
      confirmApprovalOfProduct: '商品發布批准',
      productContentConfirmed: '已確認商品內容。',
      confirmForPublishingProducts: '商品發布確認',
      productPublishedConfirmed: '已批准商品發布。',
      productSaved: '已保存商品',
      languageSetting: '語言設置',
      languageSelection: '選擇語言',
      productTitle: '商品名稱（訂購憑證名稱）',
      origin: '出發地',
      destination: '目的地',
      checkinPoints: '集合地點',
      checkoutPoints: '解散地點',
      pickupLocation: '上車地點',
      dropoffLocations: '下車地點',
      cancellationPolicy: '取消政策',
      faqSetting: '常見問題設置',
      chosenLanguage: '刊登語言',
      timeZone: '國家・地區（時區）',
      category: '類別',
      useQrCode: '使用電子票',
      qrcodeType: 'QR Code 類型',
      voucherSetting: '訂購憑證設置',
      appointmentSetting: '訂購規則設置',
      appointmentNotification: '訂單通知',
      useEticketItems: '電子票使用項目',
      qrcodeTemplate: 'QR Code模板',
      qrcodeHeader: 'QR Code標題',
      qrcodeFooter: 'QR Code頁腳',
      qrcodeGenerator: 'QR Code生成碼',
      redemptionUrlTemplate: '兌換網址模板',
      adminSettingSaved: '已保存管理設置',
      allotmentSettingSaved: '已保存方案設置',
      planSelection: '選擇方案',
      planId: '方案ID',
      systemCharge: '系統費用',
      productInfo: '商品資料',
      supplierId: '供應商ID',
      supplierName: '供應商名稱',
      name: '商品名稱',
      editMemo: '編輯筆記',
      editTag: '編輯標籤',
      publishActivityConfirm: '要發布本商品嗎？',
      publishActivitySuccess:
        '※商品發布成功後，被添加至價格組別內的代理商即可查閱商品頁面。',
      inPublish: '發布中',
      isActivityPublished: '商品發布成功',
      isActivityPublishedFailed: '商品發布失敗',
      contactUs: '請洽詢至系統負責人，不便之處還請見諒。',
      leaveCurrentPage: '離開此頁面',
      continueEditing: '繼續編輯',
      salesStatus: '顯示本商品',
      hideFromSearch: '顯示於商品列表',
      salesStatusNotes:
        '※若變更商品顯示狀態，需重新發布才可成功變更，敬請注意。',
      hideFromSearchNotes:
        '※若關閉顯示商品列表功能，本商品將無法於搜尋結果或商品列表中顯示，敬請注意。',
      qrCodeTips:
        '※如希望設定『QR Code一人一碼』或『Mogiri電子票』，請聯絡本公司的系統負責人。'
    },
    plan: {
      saveConfirm: '已保存方案',
      name: '方案名稱',
      save: '保存',
      planPublish: '顯示刊登方案',
      bookingRule: '訂購規則設置',
      voucherExpiration: '訂購憑證有效期限',
      expirationVisibilitySetting: '隱藏訂購憑證有效期限',
      visibilitySetting: {
        hideFromVoucher: '隱藏訂購憑證',
        hideFromActivityDetails: '隱藏頁面（商品內容）',
        hideFromBookingDetails: '隱藏頁面（訂單詳情）'
      },
      checkinPoint: '集合地點',
      checkoutPoint: '解散地點',
      pickupLocation: '上車地點',
      dropoffLocation: '下車地點',
      unitSetting: '單位設置',
      priceSetting: '費用設置',
      bookingAgentRequiredInfo: '代表人資料-每筆訂單（必填）',
      bookingAgentRecommendedInfo: '代表人資料-每筆訂單（任意）',
      bookingOtherRequiredInfo: '其他資料-每筆訂單（必填）',
      bookingOtherRecommendedInfo: '其他資料-每筆訂單（任意）',
      participantRequiredInfo: '使用者資料-每位參加者（必填）',
      participantRecommendedInfo: '使用者資料-每位參加者（任意）',
      customBookingInfo: '自訂訂購資料',
      settingUnable: '結束日期無法設置於開始日期以前',
      description: '方案說明',
      imageSetting: '圖像設置',
      availablePeriod: '發布區間',
      reservationRestrict: '訂購數量限制',
      pieces: '張',
      min: '最低',
      max: '最多',
      tilde: '〜',
      checklistLabel: '參加前・參加時注意事項（費用包含等）',
      startTime: '開始時間',
      setSchedule: '行程設置',
      editConfirm: '確認編輯內容',
      leave: '離開此頁面',
      continueEditing: '繼續編輯',
      code: {
        id: '方案ID',
        coupon: '隱藏套餐子商品訂購憑證',
        backendId: '庫存編號設置',
        backendIdLabel: '請選擇庫存設置',
        goToSetting: '若未設置庫存，請輸入<0>至</0>進行設置',
        backend: 'backend資訊',
        backendLabel: '請選擇希望聯合的backend項目',
        productCode: 'Product Code',
        productCodeLabel: '請輸入Product Code',
        unit: 'Unit設置',
        unitType: '單位類型',
        unitRange: '單位範圍',
        unitCode: 'Unit Code',
        startTimeTitle: '開始時間設置',
        startTime: '開始時間',
        startTimeCode: 'Start time Code',
        codeLabel: '請輸入Code'
      },
      planPublishNotes:
        '※希望變更方案顯示狀態時，需重新發布才可變更成功，敬請注意。',
      leavePrompt: '方案仍在編輯中，您確定要離開此頁面嗎？'
    },
    login: {
      title: 'Welcome to GDS',
      email: '電子信箱',
      password: '密碼',
      signIn: '登入',
      forgotPassword: '忘記密碼',
      googleLogin: '使用Google帳號登入'
    },
    password: {
      changePassword: '變更密碼',
      resetPassword: '重設密碼',
      sendResetEmail: '發送重設密碼電子郵件',
      oldPassword: '舊密碼',
      newPassword: '新密碼',
      changePasswordSuccess: '已變更密碼'
    }
  }
};
