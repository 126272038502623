// Generated i18n file
export default {
  unit: {
    AGE_ADULT_AND_CHILD: '大人和孩子共通（{n}-{m}）岁',
    AGE_ADULT: '大人（{n}-{m}）岁',
    AGE_YOUTH: '青少年（{n}-{m}）岁',
    AGE_CHILD: '儿童（{n}-{m}）岁',
    AGE_SENIOR: '老年（{n}-{m}）岁',
    AGE_INFANT: '幼儿（{n}-{m}）岁',
    AGE_STUDENT: '学生（{n}-{m}）岁',
    AGE_HIGH_SCHOOL: '高中生（{n}-{m}）岁',
    AGE_INTERMEDIATE_SCHOOL: '初中生（{n}-{m}）岁',
    AGE_ELEMENTALY_SCHOOL: '小学生（{n}-{m}）岁',
    AGE_DISABLED_ADULT: '残障成人（{n}-{m}）岁',
    AGE_DISABLED_YOUTH: '残障青少年（{n}-{m}）岁',
    AGE_DISABLED_CHILD: '残障儿童（{n}-{m}）岁',
    AGE_SUPPORTER_ADULT: '护理者（成人）',
    AGE_SUPPORTER_CHILD: '护理者（儿童）',
    MINUTES_VEHICLES: '{n} 分钟',
    HOURS_VEHICLES: '{n} 小时',
    DAYS_VEHICLES: '{n} 天',
    WEEKS_VEHICLES: '{n} 周',
    NIGHTS_VEHICLES: '{n} 晚',
    CHARTER_VAR: '1台（{n}-{m}）人',
    GROUP_VAR: '1组（{n}-{m}）人',
    ROOM_VAR: '1间房（{n}-{m}）人',
    BOAT_VAR: '1艘船（{n}-{m}）人',
    PET: '宠物',
    PET_CAT: '猫',
    PET_DOG: '狗',
    PET_DOG_S: '小型犬',
    PET_DOG_M: '中型犬',
    PET_DOG_L: '大型犬',
    PET_DOG_XL: '超大型犬',
    PET_OTHER: '其他宠物'
  },
  collectingType: {
    PER_PARTICIPANT: '依每位参与者',
    PER_BOOKING: '依每笔预约'
  },
  collectingTiming: {
    ON_BOOKING: '预约时需要',
    BEFORE_ACTIVITY: '活动前需要',
    OPTIONAL: '可选'
  },
  formType: {
    DATE_ONLY_PAST: '仅限过去日期',
    DATE_ONLY_FUTURE: '仅限未来日期',
    TIME: '时间',
    YES_NO: '是/否',
    DROPDOWN: '下拉选择框',
    RADIO: '单选按钮',
    CHECK: '勾选框',
    TEXT: '文字文本',
    TEXT_ALPHANUMERIC: '文字（字母与数字）',
    TEXT_PHONE: '电话号码',
    TEXTAREA: '文本区域'
  },
  formatType: {
    BEFORE_ACTIVITY_FORWARD_FIXED_CLOCK:
      '活动前{D}天，{HH:MM}当地时间后取消，将收取{N}取消费用。',
    BEFORE_ACTIVITY_FORWARD_DURATION:
      '活动前{T}小时内取消，将收取{N}取消费用。',
    AFTER_BOOKING_FORWARD_DURATION:
      '订单确认后{T}小时内取消，将收取{N}取消费用。',
    AFTER_BOOKING_FORWARD_FIXED_CLOCK:
      '订单确认后{D}天，{HH:MM}当地时间后取消，将收取{N}取消费用。',
    BEFORE_ACTIVITY_BACKWARD_FIXED_CLOCK:
      '活动前{D}天，{HH:MM}当地时间前取消，将收取{N}取消费用。',
    BEFORE_ACTIVITY_BACKWARD_DURATION: '活动前{T}小时取消，将收取{N}取消费用。',
    AFTER_BOOKING_BACKWARD_DURATION:
      '订单确认后{T}小时内取消，将收取{N}取消费用。',
    AFTER_BOOKING_BACKWARD_FIXED_CLOCK:
      '订单确认后{D}天，{HH:MM}当地时间前取消，将收取{N}取消费用。'
  },
  confirmationType: {
    FREE_SALE: '参加日指定（自由售票）',
    FREE_SALE_OPEN_DATE: '不指定日期（自由售票）',
    INVENTORY: '参加日指定（实时库存）',
    REQUEST: '需要指定日期（请求）'
  },
  voucherType: {
    NONE: '不需要',
    COLLECTIVE: 'QR码一单一码',
    INDIVIDUAL: 'QR码一人一码'
  },
  qrcodeType: {
    QRCODE: 'QRCODE',
    TEXT: '文字',
    URL: 'URL',
    BARCODE_128: '条形码128',
    BARCODE_93: '条形码93',
    BARCODE_39: '条形码39',
    BARCODE_25: '条形码25'
  },
  voucherMedium: {
    ELECTRONIC: '无需打印（屏幕提示）',
    PAPER: '必须打印'
  },
  cancellationFeeType: {
    PERCENTAGE: '百分比',
    ADVANCED_PERCENTAGE: '高级百分比设置',
    FIXED: '固定费用',
    NOT_CANCELLABLE: '不可取消'
  },
  datePeriodUnit: {
    DAY: '日计算',
    MONTH: '月计算'
  },
  basePrice: {
    GROSS: '销售价格',
    NET: '批发价格',
    SUPPLIER_NET: '供应商批发价格'
  },
  checklistType: {
    RESTRICTION: '参加限制',
    REQUIRED_ITEM_TO_BRING_AND_ATTIRE: '参加所需的服装和物品',
    REQUIRED_OTHER: '参加时其他必需事项',
    REQUIRED_ADDITIONAL_NOTE: '备注和其他',
    NICE_TO_HAVE_ITEM_TO_BRING_AND_ATTIRE: '建议携带的物品和服装',
    NICE_TO_HAVE_OTHER: '其他建议物品',
    INCLUDED: '包含项目'
  },
  week: {
    SUNDAY: '星期天',
    MONDAY: '星期一',
    TUESDAY: '星期二',
    WEDNESDAY: '星期三',
    THURSDAY: '星期四',
    FRIDAY: '星期五',
    SATURDAY: '星期六'
  },
  category: {
    RAILWAY_TICKET: '铁路车票',
    AIRPORT_TRANSPORTATION: '机场接送',
    SHINKANSEN: '新干线',
    EXPRESS_BUS: '高速巴士',
    BUS_TOUR: '巴士游览',
    CRUISES: '渡轮和游轮',
    RENTAL_CAR: '租车',
    CHARTER: '包车',
    ATTRACTION: '景点（门票）',
    THEME_PARK: '主题公园',
    MUSEUM_GALLERY: '博物馆和画廊',
    AQUARIUM_ZOO: '水族馆和动物园',
    TEMPLES_SHINES: '寺庙和神社',
    TOWER_BUILDING: '塔和观景台',
    JAPANESE_RESTAURANT: '和食餐厅',
    WESTERN_RESTAURANT: '西餐餐厅',
    CHINESE_RESTAURANT: '中餐餐厅',
    OTHER_RESTAURANT: '其他餐厅',
    DESSERT_BEVERAGE: '甜点和饮料',
    SIGHTSEEING_TOUR: '观光旅游',
    MARINE_SPORTS: '水上运动',
    OUTDOOR_ACTIVITIES: '户外活动',
    ONSEN: '温泉',
    SPA_MASSAGE: '水疗和按摩',
    JAPANESE_CULTURAL_EXPERIENCE: '日本文化体验',
    JAPANESE_CRAFT_EXPERIENCE: '日本工艺体验',
    JAPANESE_COOKING_EXPERIENCE: '日本料理体验',
    OTHER_EXPERIENCE: '其他体验',
    HOTEL: '酒店',
    RYOKAN: '旅馆',
    VACATION_RENTAL: '民宿',
    BUNDLE: '套餐',
    PASS: '通行证',
    OTHERS: '其他'
  },
  bookingField: {
    PARTICIPANT_EMAIL_ADDRESS: '参与者电子邮件地址',
    HOTEL_NAME: '住宿名称',
    HOTEL_ADDRESS: '住宿地址',
    HOTEL_RESERVATION_LAST_NAME: '住宿预约代表者姓氏',
    HOTEL_RESERVATION_FIRST_NAME: '住宿预约代表者名字',
    HOTEL_TEL: '住宿电话号码',
    ARRIVAL_DATE: '到达日期',
    ARRIVAL_TIME: '到达时间',
    ARRIVAL_FLIGHT_NUMBER: '到达航班号',
    DEPARTURE_DATE: '出发日期',
    DEPARTURE_TIME: '出发时间',
    DEPARTURE_FLIGHT_NUMBER: '出发航班号',
    DESTINATION_EMAIL: '目的地联系电子邮件',
    DESTINATION_TEL: '目的地联系电话号码',
    PARTICIPANT_RESIDENT_REGION: '参与者居住国家/地区',
    PARTICIPANT_LANGUAGE: '参与者语言'
  },
  extendedBookingField: {
    PASSENGERS_QUANTITY: '乘客数量',
    PREFERRED_START_TIME: '希望开始时间',
    PREFERRED_PICK_UP_LOCATION: '希望接送地点',
    PREFERRED_DROP_OFF_LOCATION: '希望送达地点',
    PARTICIPANTS_QUANTITY: '参与人数',
    LUGGAGE_QUANTITY: '行李数量（总计）',
    CHILDREN_WITHOUT_SEAT_QUANTITY: '不需要座位的幼儿数量',
    CHILD_SEAT_QUANTITY_PAID: '需要儿童座椅数量（现场支付）',
    CHILD_SEAT_QUANTITY_FREE: '需要儿童座椅数量（免费）',
    PREFERRED_GUIDE_LANGUAGE_CODE: '希望的导游语言',
    PREFERRED_DRIVER_LANGUAGE_CODE: '希望的司机语言'
  },
  extendedParticipantField: {
    PASSPORT_FIRST_NAME: '参加者名字（护照上的拼音）',
    PASSPORT_LAST_NAME: '参加者姓氏（护照上的拼音）',
    PASSPORT_NATIONALITY: '国籍',
    PASSPORT_NUMBER: '护照号码',
    DATE_OF_BIRTH: '出生日期',
    AGE: '年龄',
    GENDER: '性别',
    EYESIGHT: '视力',
    WEIGHT_KG: '体重（公斤）',
    HEIGHT_CM: '身高（厘米）',
    SHOE_SIZE_CM: '鞋码（厘米）',
    CLOTHES_SIZE: '服装尺寸',
    VEGETARIAN_MEALS: '素食需求',
    DIVING_EXPERIENCE_TIMES: '潜水经验次数',
    RENTAL_EQUIPMENTS_FREE: '需要租借设备（免费）',
    RENTAL_EQUIPMENTS_PAY_LOCALLY: '需要租借设备（现场支付）'
  },
  templateStatus: {
    DRAFT: '草稿',
    REVIEWING: '正在审核',
    APPROVED: '已批准',
    PUBLISHED: '已公开'
  },
  universalType: {
    TRUE: '各计划通用',
    FALSE: '各计划不同'
  },
  datePeriod: {
    DAY: '天数',
    MONTH: '月数',
    tilde: '〜'
  },
  voucherExpirationType: {
    FROM_PURCHASE_DATE: '从购买日起',
    FROM_ACTIVITY_DATE: '从活动日期',
    FIXED_DATE: '固定日期',
    RELATIVE_FIXED_DATE: '相对固定日期'
  },
  operation: {
    CREATE: '商品已创建',
    UPDATE: '编辑',
    DELETE: '删除',
    SUBMIT_FOR_REVIEW: '提交审核',
    REJECT: '被驳回',
    APPROVE: '批准公开申请',
    PUBLISH: '已发布'
  },
  jobState: {
    ENABLED: '成功',
    PAUSED: '停止',
    DISABLED: '无效',
    UPDATE_FAILED: '更新失败',
    ERROR: '错误'
  },
  salesStatus: {
    ON: '正常',
    OFF: '已存档'
  }
};
