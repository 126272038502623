// Generated i18n file
export default {
  common: {
    delete: 'Delete',
    updateSuccess: 'Updated',
    sync: 'Sync'
  },
  confirmDelete: {
    title: 'Please Confirm',
    prompt: 'Are you sure you want to delete this?'
  },
  supplierSelect: {
    selectSupplier: 'Please select a supplier'
  },
  agentGroup: {
    addAgent: 'Add Agency',
    deleteAgent: 'Delete Agency'
  }
};
