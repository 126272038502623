// Generated i18n file
export default {
  common: {
    delete: '删除',
    updateSuccess: '已更新',
    sync: '同步'
  },
  confirmDelete: {
    title: '请确认',
    prompt: '您确定要删除吗？'
  },
  supplierSelect: {
    selectSupplier: '请选择供应商'
  },
  agentGroup: {
    addAgent: '添加代理商',
    deleteAgent: '删除代理商'
  }
};
