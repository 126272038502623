import i18n from 'i18next';
import {
  DEFAULT_LOCALE,
  LOCALE_LIST
} from '@link/gds-supplier/constants/locale';
import { initReactI18next } from 'react-i18next';

import jaShare from '@link/gds-share/i18n/lang/ja';
import enShare from '@link/gds-share/i18n/lang/en';
import zhShare from '@link/gds-share/i18n/lang/zh';
import ztShare from '@link/gds-share/i18n/lang/zt';
import koShare from '@link/gds-share/i18n/lang/ko';

import enUI from '@linktivity/link-ui/lang/en';
import jaUI from '@linktivity/link-ui/lang/ja';
import zhUI from '@linktivity/link-ui/lang/zh';
import ztUI from '@linktivity/link-ui/lang/zt';
import koUI from '@linktivity/link-ui/lang/ko';

import jaText from './lang/ja';
import enText from './lang/en';
import zhText from './lang/zh';
import ztText from './lang/zt';
import koText from './lang/ko';

import jaConst from './const/ja';
import enConst from './const/en';
import zhConst from './const/zh';
import ztConst from './const/zt';
import koConst from './const/ko';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const resources = {
  en: {
    translation: {
      ...enUI,
      ...enText,
      const: enConst,
      share: enShare
    }
  },
  ja: {
    translation: {
      ...jaUI,
      ...jaText,
      const: jaConst,
      share: jaShare
    }
  },
  ko: {
    translation: {
      ...koUI,
      ...koText,
      const: koConst,
      share: koShare
    }
  },
  zh: {
    translation: {
      ...zhUI,
      ...zhText,
      const: zhConst,
      share: zhShare
    }
  },
  zt: {
    translation: {
      ...ztUI,
      ...ztText,
      const: ztConst,
      share: ztShare
    }
  }
} as const;

i18n.use(initReactI18next).init({
  lng: DEFAULT_LOCALE,
  returnNull: false,
  resources
});

export function setLocale(locale: string): string {
  i18n.changeLanguage(locale);
  document.documentElement.setAttribute('lang', locale);
  localStorage.setItem('locale', locale);
  return locale;
}

function getLocaleParams() {
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang') || '';
  if (LOCALE_LIST.includes(lang)) {
    return lang;
  }
}

(() => {
  const defaultLocale =
    getLocaleParams() || localStorage.getItem('locale') || DEFAULT_LOCALE;
  setLocale(defaultLocale);
})();

export default i18n;
