// Generated i18n file
export default {
  common: {
    delete: '刪除',
    updateSuccess: '已更新',
    sync: '同步'
  },
  confirmDelete: {
    title: '請確認',
    prompt: '您確定要刪除嗎？'
  },
  supplierSelect: {
    selectSupplier: '請選擇供應商'
  },
  agentGroup: {
    addAgent: '添加代理商',
    deleteAgent: '刪除代理商'
  }
};
