import { memo, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@linktivity/link-ui';
import { Globe } from '@linktivity/link-icons';
import { setLocale } from '@link/gds-supplier/i18n';
import styles from './header.module.css';

const i18nList = [
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'ja',
    label: '日本語'
  },
  {
    value: 'zh',
    label: '简体中文'
  },
  {
    value: 'zt',
    label: '繁體中文'
  },
  {
    value: 'ko',
    label: '한국어'
  }
];

const LocaleView = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const { i18n } = useTranslation();
  const currentLocale = useMemo(
    () => i18nList.find(locale => locale.value === i18n.language)?.label,
    [i18n.language]
  );

  const changeLocale = useCallback((locale: string) => {
    setLocale(locale);
  }, []);

  const localeButton = useMemo(
    () => (
      <button
        className={styles.button}
        type="button"
        onClick={() => setOpenMenu(visible => !visible)}
      >
        <Globe className={styles.icon} />
        <span className={styles.text}>{currentLocale}</span>
      </button>
    ),
    [currentLocale]
  );
  return (
    <Dropdown
      target={localeButton}
      visible={openMenu}
      sameWidth={false}
      onClose={() => setOpenMenu(false)}
    >
      <Dropdown.Menu className={styles.dropdownMenu}>
        {i18nList.map(locale => (
          <Dropdown.Item key={locale.value}>
            <button
              className={styles.dropdownButton}
              onClick={() => changeLocale(locale.value)}
            >
              {locale.label}
            </button>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default memo(LocaleView);
